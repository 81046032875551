import React from "react"

import './style.css'

function Header() {
    return(
        <header>
            <div>
                <h1>BOB BAKER VIOLIN </h1>
                 {/* <span><sub className='location'>Sisters, Oregon</sub></span> */}
            </div>
        </header>
    )
    }

export default Header;
